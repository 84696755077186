
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../assets/fonts/noto_sans/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: 'Effra';
  src: url('../assets/fonts/EffraW02-Regular.woff2') format('woff2'),
    url('../assets/fonts/EffraW02-Regular.woff') format('woff'),
    url('../assets/fonts/EffraW02-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url('../assets/fonts/EffraW02-Light.woff2') format('woff2'),
    url('../assets/fonts/EffraW02-Light.woff') format('woff'),
    url('../assets/fonts/EffraW02-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url('../assets/fonts/EffraW02-Medium.woff2') format('woff2'),
    url('../assets/fonts/EffraW02-Medium.woff') format('woff'),
    url('../assets/fonts/EffraW02-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url('../assets/fonts/EffraW02-Bold.woff2') format('woff2'),
    url('../assets/fonts/EffraW02-Bold.woff') format('woff'),
    url('../assets/fonts/EffraW02-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'helfa';
  src: url('../assets/fonts/helfa-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helfa';
  src: url('../assets/fonts/helfa-light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'helfa';
  src: url('../assets/fonts/helfa-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'helfa';
  src: url('../assets/fonts/helfa-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
